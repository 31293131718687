<template lang='pug'>
div.form__wrapper
  img.logo(src='../assets/logo.png')
  h1 Edit Account
  el-form(v-if='!submitted', :model='form', :rules='rules', ref='editAccountForm',
          v-loading='loading', @keyup.enter.native='editAccount')
    div.double-form-item
      el-form-item(prop='first_name')
        el-input(v-model='form.first_name', placeholder='First name', autofocus)
      el-form-item(prop='last_name')
        el-input(v-model='form.last_name', placeholder='Last name')
    el-form-item(prop='phone_number')
      el-input(v-model='form.phone_number', placeholder='Phone number')
    div.spacer
    el-form-item(prop='old_password')
      el-input(v-model='form.old_password', type='password', placeholder='Current password')
    div.double-form-item
      el-form-item(prop='new_password')
        el-input(v-model='form.new_password', type='password', placeholder='New password')
      el-form-item(prop='new_password_confirmation')
        el-input(v-model='form.new_password_confirmation', type='password', placeholder='Confirm')
    
    el-form-item
      el-button.submit(type='primary', @click='editAccount') Save account changes
  div(v-else)
    p Your account changes have been saved.
</template>

<script>
import api from '../api'

export default {
  name: 'EditAccount',
  data () {
    let checkPasswordsMatch = (rule, value, callback) => {
      // check that password confirmation matches password
      if (this.form.new_password && this.form.new_password.length > 0 
          && value !== this.form.new_password) {
        callback(new Error('Passwords don\'t match'))
      } else {
        callback()
      }
    }

    let checkEditingPassword = (rule, value, callback) => {
      // if changing password, require old password
      if (this.form.new_password && this.form.new_password.length > 0 
          && (!value || value.length === 0)) {
        console.log('got here')
        callback(new Error('Enter your current password'))
      } else {
        callback()
      }
    }

    let checkValidPhoneNumber = (rule, value, callback) => {
      // strip everything but numbers from phone and check length
      if (value && value.length > 0 
          && value.replace(/[^\/\d]/g,'').length < 10) {
        callback(new Error('Enter a valid phone number'))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      submitted: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        old_password: null,
        new_password: null,
        new_password_confirmation: null
      },
      rules: {
        phone_number: [
          { validator: checkValidPhoneNumber, trigger: 'blur' }
        ],
        old_password: [
          { validator: checkEditingPassword, trigger: 'submit' }
        ],
        new_password: [
          { min: 14, message: 'Use 14 or more characters', trigger: 'blur' }
        ],
        new_password_confirmation: [
          { validator: checkPasswordsMatch, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    editAccount () {
      this.$refs.editAccountForm.validate((valid) => {
        if (valid) {
          this.loading = true
          api.post('edit', this.form).then((res) => {
            this.loading = false
            this.submitted = true
          }).catch((error) => {
            this.loading = false
            this.$message.error('Something has gone wrong. Contact proton.ai if this problem persists.')
          })
        }
      })
    }
  }
}
</script>

<style lang='scss'>
@import '../scss/common';
</style>